import styled from "styled-components";
import { Grid } from "@mui/material";

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Map } from "@roambee/client-styleguide";

const BoxCard = styled.div`
	width: 100%;
	height: 200px;
	background-color: #fff;
	border-radius: 0.5rem !important;
	box-shadow: rgba(16, 15, 22, 0.06) 0px 6px 12px;
`;

const ContainerDetail = () => {
	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={6}>
					<BoxCard />
				</Grid>
				<Grid item xs={6}>
					<BoxCard />
				</Grid>
				<Grid item xs={12}>
					<BoxCard />
				</Grid>
				<Grid item xs={12}>
					<Map zoom={8}></Map>
				</Grid>
			</Grid>
		</>
	);
};

export default ContainerDetail;
