import { Outlet } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Tabs, Table, Tag, Button } from "@roambee/client-styleguide";
import "./Container.scss";
import styled from "styled-components";
import { Settings, Share } from "@carbon/icons-react";

const data = [
	{ label: "All", active: true },
	{ label: "Deferred", active: false },
	{ label: "Active", active: false },
	{ label: "Completed", active: false }
];

const TrackButton = styled(Button)`
	float: right;
`;

const Container = () => {
	const columns = [
		{
			field: "name",
			headerName: "Name",
			width: 12,
			isSortable: true,
			isSearchable: false
		},
		{
			field: "status",
			headerName: "Status",
			width: 9,
			isSortable: true,
			isSearchable: false,
			renderCell: (params) => {
				return <Tag label={params?.row.status} color={params?.row?.tagColor} backgroundColor={params?.row?.backgroundTagColor} />;
			}
		},
		{ field: "account", headerName: "Account", width: 9, isSortable: true, isSearchable: false },
		{
			field: "container",
			headerName: "BOL/Container",
			width: 12,
			isSortable: true,
			isSearchable: false,
			renderCell: (params) => {
				return (
					<>
						<Grid>
							<Grid xs={12}>
								<Typography variant="caption">{params?.row?.bol}</Typography>
							</Grid>
							<Grid xs={12}>
								<Typography variant="caption">{params?.row?.container}</Typography>
							</Grid>
						</Grid>
					</>
				);
			}
		},
		{
			field: "progress",
			headerName: "Progress",
			width: 10,
			isSortable: false,
			isSearchable: false
		},
		{
			field: "sensors",
			headerName: "Sensors",
			width: 10,
			isSortable: false,
			isSearchable: false
		},
		{
			field: "lastupdate",
			headerName: "Last Updated",
			width: 9,
			isSortable: true,
			isSearchable: false
		}
	];
	const transactions = [
		{
			name: "Shipment Name",
			status: "Started",
			backgroundTagColor: "#E3F5E1",
			tagColor: "#00A91C",
			account: "Account Name",
			container: "Container Number",
			bol: "BOL Number",
			progress: "50%",
			sensors: "sensors",
			lastupdate: "4h ago"
		},
		{
			name: "Shipment Name",
			status: "Started",
			backgroundTagColor: "#E3F5E1",
			tagColor: "#00A91C",
			account: "Account Name",
			container: "Container Number",
			bol: "BOL Number",
			progress: "50%",
			sensors: "sensors",
			lastupdate: "4h ago"
		},
		{
			name: "Shipment Name",
			status: "Started",
			backgroundTagColor: "#E3F5E1",
			tagColor: "#00A91C",
			account: "Account Name",
			container: "Container Number",
			bol: "BOL Number",
			progress: "50%",
			sensors: "sensors",
			lastupdate: "4h ago"
		},
		{
			name: "Shipment Name",
			status: "Discharged",
			backgroundTagColor: "#E3F5E1",
			tagColor: "#00A91C",
			account: "Account Name",
			container: "Container Number",
			bol: "BOL Number",
			progress: "50%",
			sensors: "sensors",
			lastupdate: "4h ago"
		},
		{
			name: "Shipment Name",
			status: "Awaiting vessel",
			backgroundTagColor: "#F9705D33",
			tagColor: "#EB1F00",
			account: "Account Name",
			container: "Container Number",
			bol: "BOL Number",
			progress: "50%",
			sensors: "sensors",
			lastupdate: "4h ago"
		}
	];
	return (
		<>
			<div className="container-tabs">
				<div className="container-tabs-row">
					<Tabs tabs={data} hideAddTab={true} />
				</div>
				<div className="container-tabs-row">
					<button className="common-button">
						<Settings size={20} />
					</button>
					<button className="common-button">
						<Share size={20} />
					</button>
					<TrackButton label="Track New" size={"small"} variant="secondary" />
				</div>
			</div>
			<Grid container sx={{ mt: 1 }} spacing={2}>
				<Grid item xs={12}>
					<Table showFilters={true} isSelectable={false} columns={columns} data={transactions} total={100} page={1} pageSize={10} />
				</Grid>
			</Grid>
			<Outlet />
		</>
	);
};

export default Container;
