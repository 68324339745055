/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { GlobalSearch } from "@roambee/client-styleguide";

const Topbar = () => {
	return (
		<>
			<GlobalSearch initial="R" name="Roambee" showSearch={false} showNotification={false} title="Shipments" menuItems={0} />
		</>
	);
};

export default Topbar;
