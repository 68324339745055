import { Grid } from "@mui/material";
import styled from "styled-components";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Tabs, InputText, Select, Button } from "@roambee/client-styleguide";
import "./Container.scss";
const data = [{ label: "Track New Shipment", active: true }];

const ContainerSelect = styled(Select)`
	height: 30px !important;
	margin-top: 5px;
	text-align: left;
`;
const TrackButton = styled(Button)`
	margin-bottom: 2rem;
`;

const ContainerTrack = () => {
	return (
		<>
			<Tabs tabs={data} />
			<div className="tracking-card">
				<Grid container justifyContent="center" marginTop={14}>
					<Grid xs={3} textAlign="center" marginTop={4} sx={{ borderRight: 1, borderColor: "#E6E6E6" }}>
						<img src={require(`../../assets/images/booking.svg`)} height="40px" width="40px" alt="Booking" />
						<p>Booking</p>
					</Grid>
					<Grid xs={3} textAlign="center" marginTop={4} sx={{ borderRight: 1, borderColor: "#E6E6E6" }}>
						<img src={require(`../../assets/images/shipping_container.svg`)} height="40px" width="40px" alt="Container" />
						<p>Container</p>
					</Grid>
					<Grid xs={3} textAlign="center" marginTop={4}>
						<img src={require(`../../assets/images/booking.svg`)} height="40px" width="40px" alt="Bill" />
						<p>Bill of lading</p>
					</Grid>
				</Grid>
				<Grid container sx={{ gap: 1 }} justifyContent="center" marginTop={6}>
					<Grid xs={4} textAlign="center">
						<InputText id="1" name="text-input" placeholder="Container Number" />
					</Grid>
					<Grid xs={4} textAlign="center">
						<ContainerSelect
							hasCheck
							name="city"
							options={[
								{
									id: "Carrier",
									label: "Carrier",
									value: "Carrier"
								},
								{
									id: "Madrid-1",
									label: "Madrid",
									value: "Madrid"
								}
							]}
							placeholder="Carrier"
						/>
					</Grid>
				</Grid>
				<Grid container justifyContent="center" marginTop={3}>
					<TrackButton label="Track Container" variant="secondary" />
				</Grid>
			</div>
		</>
	);
};

export default ContainerTrack;
