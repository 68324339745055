import Topbar from "./Topbar";
import { Outlet } from "react-router-dom";
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { EventEmitter } from "@roambee/client-utility";
import { useEffect, useState } from "react";

const MainLayout = (props) => {
	const [isWide, setWide] = useState(true);

	EventEmitter.addListener("toggleSideNav", (eventData) => {
		setWide(eventData);
	});

	useEffect(() => {
		//if one of the accordion menu is open and active close the other menu accordions
		window.addEventListener("resize", () => {
			if (window.innerWidth > 400 && window.innerWidth < 1200) {
				const container = document.querySelector("#main-layout");
				container?.classList.add("sidenav-small");
			} else {
				const container = document.querySelector("#main-layout");
				container?.classList.remove("sidenav-small");
			}
		});
	}, []);

	return (
		<section id="main-layout" className={isWide ? "sidenav-normal" : "sidenav-small"}>
			<section id="header">
				<Topbar />
			</section>
			<section id="container-layout">
				{props.routes}
				<Outlet />
			</section>
		</section>
	);
};

export default MainLayout;
