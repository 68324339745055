import Container from "../components/container/Container";
import ContainerTrack from "../components/container/ContainerTrack";
import ContainerDetail from "../components/container/ContainerDetail";
import { DeliveryParcel } from "@carbon/icons-react";

const routes = (isLoggedIn) => [
	{
		path: "container",
		title: "Container",
		icon: <DeliveryParcel size={20} />,
		visible: true,
		children: [
			{
				path: "",
				element: isLoggedIn && <Container />,
				title: "Container",
				visible: false
			},
			{
				path: "track",
				element: isLoggedIn && <ContainerTrack />,
				title: "Container Track",
				visible: false
			},
			{
				path: ":uuid",
				element: isLoggedIn && <ContainerDetail />,
				title: "Container Detail",
				visible: false
			}
		]
	}
];

export default routes;
