/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useRef, useState } from "react";
import { useRoutes } from "react-router";
import { Navigate } from "react-router-dom";

// @ts-ignore
import { API, setAuthUser, setRoutes } from "@roambee/client-utility";
// @ts-ignore
import { Loader } from "@roambee/client-styleguide";

import routes from "./core/Routes";
import MainLayout from "./pages/MainLayout";
import "./App.scss";

function App() {
	const [isLoading, setIsLoading] = useState(true);
	const [authenticated, setAuthenticated] = useState(false);

	useEffect(() => {
		API("POST", "/user/me", {})
			.then((result) => {
				setAuthenticated(true);
				setAuthUser(result);
			})
			.catch(() => {
				setAuthenticated(false);
			})
			.finally(() => {
				setIsLoading(false); // Regardless of success or error, loading is done
			});
	}, []);

	const content = useRoutes(routes(authenticated));
	setRoutes(routes);

	if (isLoading) {
		return <Loader />;
	}

	return (
		<section>
			{authenticated ? (
				<div>
					<MainLayout routes={content} />
				</div>
			) : (
				!isLoading && <Navigate to="/auth" />
			)}
		</section>
	);
}

export default App;
